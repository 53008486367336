<template>
  <app-layout>
    <div class="flex flex-col items-center py-4">
      <div class="w-full lg:w-1/2">
        <div class="flex flex-row items-center inline py-4">
          <sa-icon-button
            iconClass="bx bx-left-arrow-alt"
            @click="$router.push({ name: 'suppliers.index' })"
            with-bg />
          <span class="mx-2 text-lg font-bold">Proveedor</span>
        </div>
        <content-card>
          <supplier-form @onSaved="savedSupplier" />
        </content-card>
      </div>
    </div>
  </app-layout>
</template>
<script>
export default {
  name: 'CreateIndex',
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout.vue'),
    ContentCard: () => import('@/components/molecules/ContentCard.vue'),
    SupplierForm: () => import('./SupplierForm.vue'),
  },
  methods: {
    savedSupplier() {
      this.$router.push({ name: 'suppliers.index' });
    }
  }
}
</script>
